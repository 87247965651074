.common__section{
    background: linear-gradient(rgba(0,0,0,0.562),rgba(0,0,0,0.562)),url("../assets/images/common-section.jpg") no-repeat center center;
    background-size: cover;
    padding: 100px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.common__section h1{
    color: #fff;
    font-weight: 600;
}

@media only screen and (max-width:768px){
    .common__section{
        padding: 70px 0px;

    }
    .common__section h1{
        font-size: 1.4rem; 
    }
}